import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { IconButton, ThemeStore } from '@malirang/front-share';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  standalone: true,
  imports: [CommonModule, IconButton],
})
export class TopBar {
  readonly themeStore = inject(ThemeStore);
  theme = this.themeStore.theme;

  header = input.required<string>();
  description = input<string>();

  toggleTheme() {
    this.themeStore.setTheme(
      this.themeStore.theme() === 'dark' ? 'light' : 'dark',
    );
  }
}
