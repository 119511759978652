import { CommonModule } from '@angular/common';
import { Component, inject, input } from '@angular/core';
import { ThemeStore } from '@malirang/front-share';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenu {
  readonly themeStore = inject(ThemeStore);

  theme = this.themeStore.theme;

  title = input.required<string>();
  logo = input<string>();
}
