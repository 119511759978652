import { computed, Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { BaseStore } from '@malirang/front-share';
import { map } from 'rxjs';

export interface IMenu {
  name: string;
  href?: string;
  children?: IMenu[];
  icon?: string;
  description?: string;
}

type State = {
  menus: IMenu[];
  path: string;
};

@Injectable({ providedIn: 'root' })
export class LayoutStore extends BaseStore<State> {
  menus$ = this.state$.pipe(map((state) => state.menus));
  path$ = this.state$.pipe(map((state) => state.path));

  menus = toSignal(this.menus$);
  path = toSignal(this.path$);

  currentMenu = computed<IMenu | undefined>(() =>
    this.menus()?.find((menu) => {
      if (menu.children) {
        return menu.children.some((child) =>
          this.path()?.includes(child.href ?? ''),
        );
      }

      return this.path()?.includes(menu.href ?? '');
    }),
  );

  currentChildMenu = computed<IMenu | undefined>(() => {
    const currentMenu = this.currentMenu();

    if (currentMenu?.children) {
      return currentMenu.children.find((child) =>
        this.path()?.includes(child.href ?? ''),
      );
    }

    return undefined;
  });

  constructor() {
    super({
      menus: [],
      path: '',
    });
  }

  setPath(path: string) {
    this.updateState({ path });
  }

  setMenus(menus: IMenu[]) {
    this.updateState({ menus });
  }
}
