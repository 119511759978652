<section [attr.data-theme]="theme()" class="theme-border side-menu-container">
  <div
    [attr.data-theme]="theme()"
    class="flex gap-2.5 items-center py-7 px-5 border-b theme-border"
  >
    <img [src]="logo() || 'https://lepisode.team/assets/logo.svg'" />
    <p [attr.data-theme]="theme()" class="font-semibold theme-text text-lg">
      {{ title() }}
    </p>
  </div>
  <div class="flex flex-col gap-1">
    <ng-content />
  </div>
</section>
